<template>
  <v-container fluid class="text-left">
    <v-row class="py-4" v-if="servicesOpted.length > 0" >
      <v-col cols="12" class="text-center">
        <div class="text-h6 font-weight-bold section-title">
          Services Opted
        </div>
      </v-col>
    </v-row>
    <div v-for="(service, index) in servicesOpted" :key="index" class="py-4">
      <div class="subtitle-1 font-weight-bold py-4">
        {{ service.bill_type }}
      </div>
      <v-row class="text-left">
        <v-col cols="12" sm="6" class="d-flex" v-if="service.bill_type !== 'Others'">
          <v-row class="job-row__border ma-1 align-center">
            <v-col cols="6" class="primary--text subtitle-2 font-weight-bold py-0">
              Supplier
            </v-col>
            <v-col cols="6" class="subtitle-2 py-0">
              {{service.supplier}}
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="6" class="d-flex" 
          v-for="(item, index) in Object.keys(service.metadata || {}).filter(key => key !== 'tax')"
          :key="index"
        >
          <v-row class="job-row__border ma-1 align-center">
            <v-col cols="6" class="text-capitalize primary--text subtitle-2 font-weight-bold py-0">
              {{ item.split('_').join(' ') }}
            </v-col>
            <v-col cols="6" class="subtitle-2 py-0">
              <span v-if="typeof(service.metadata[item]) == 'boolean'">
                {{ service.metadata[item] ? 'Yes' : 'No' }}
              </span>
              <span v-else-if="isDatetime(service.metadata[item])">
                {{ formatDate(service.metadata[item]) }}
              </span>
              <span v-else>
                {{ service.metadata[item] }}
              </span>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="6" class="d-flex">
          <v-row class="job-row__border ma-1 align-center">
            <v-col cols="6" class="primary--text subtitle-2 font-weight-bold py-0">
              Remarks
            </v-col>
            <v-col cols="6" class="subtitle-2 py-0">
              {{service.remarks}}
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
  export default {
    name: 's-show-services-opted',
    props: {
      operation: {
        type: Object,
        default: {},
      },
      vesselInfo: {
        type: Object,
        default: {},
      },
    },
    data () {
      return {
        servicesOpted: [],
      };
    },
    methods: {
      isDatetime(value) {
        try {
          const date = new Date(value);
          return date.toISOString() === value;
        } catch (e) {
          return false;
        }
      },
      formatDate(date) {
        if (!date) return '';
        const [day, month, year] = new Date(date).toLocaleDateString().split('/');
        const [hr, min, sec] = new Date(date).toLocaleTimeString('en-US', { hour12: false }).split(':');
        return `${year}-${month}-${day} ${hr}:${min}`;
      },
      handleOperationInit() {
        this.getBilledItems();
      },
      getBilledItems() {
        const fieldsToRemove = ['percentage', 'price_per_hour', 'bank_charges', 'currency', 'price_per_application', 'provisions_list', 'price_per_member'];

        this.$api.operation_billed_items.billed_items_checklist(this.operation.id)
          .then((response) => {
            const filteredServices = response.checklist
              .filter(service => service.bill_type !== 'Agency Fees')
              .map(service => {
                const filteredMetadata = Object.keys(service.metadata || {}).reduce((acc, key) => {
                  if (!fieldsToRemove.includes(key) && typeof service.metadata[key] !== 'boolean') {
                    acc[key] = service.metadata[key];
                  }
                  return acc;
                }, {});

                return {
                  ...service,
                  metadata: filteredMetadata,
                };
              })
              .sort((a, b) => a.bill_type.localeCompare(b.bill_type));
            this.$set(this, 'servicesOpted', filteredServices);
          })
          .catch((err) => {
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          });
      }
    },
    watch: {
      operation(value) {
        this.handleOperationInit();
      },
    },
    created() {
      this.handleOperationInit();
    },
  };
</script>

<style scoped>
  .job-row__border {
    border-bottom: 1px solid lightgray;
  }
  .section-title {
    background-color: #e6e6e6 !important;
    padding: 4px;
  }
</style>