<template>
  <v-container fluid>
    <v-form ref="form" v-model="form" @submit.prevent="submit">
      <v-row>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
          <v-switch
            dense
            hide-details
            v-model="arrivalCrewSignOnService.metadata.arrange_by_owner"
            label="Arrange By Principal?"
            class="mt-0 pt-0"
          ></v-switch>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0" v-show="!arrivalCrewSignOnService.metadata.arrange_by_owner">
          <v-text-field
            label="Total Crew Arrived"
            placeholder="Enter Total Crew Arrived"
            :rules="[rules.negative]"
            outlined
            dense
            type="number"
            v-model="arrivalCrewSignOnService.metadata.total_members_arrived"
            prepend-inner-icon="mdi-account"
            @input="calculateArrivalSignonPrice"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0" v-show="!arrivalCrewSignOnService.metadata.arrange_by_owner" v-if="$root.hasAccess('can_manage_operation_amount_details')">
          <v-text-field
            label="Price Per Crew"
            placeholder="Enter Price Per Crew"
            :rules="[rules.negative]"
            outlined
            dense
            type="number"
            v-model="arrivalCrewSignOnService.metadata.price_per_member"
            prepend-inner-icon="mdi-currency-usd"
            @input="calculateArrivalSignonPrice"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0" v-show="!arrivalCrewSignOnService.metadata.arrange_by_owner" v-if="$root.hasAccess('can_manage_operation_amount_details')">
          <v-text-field
            label="Arrival Crew Sign On Service Charges"
            placeholder="Arrival Crew Sign On Service Charges"
            outlined
            dense
            type="number"
            v-model="arrivalCrewSignOnService.amount"
            prepend-inner-icon="mdi-currency-usd"
            :hint="arrivalCrewHintText"
            :rules="amountRules"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0" v-show="!arrivalCrewSignOnService.metadata.arrange_by_owner">
          <v-select
            label="Crew Sign On Supplier"
            placeholder="Select Supplier"
            outlined
            dense
            v-model="arrivalCrewSignOnService.card_id"
            prepend-inner-icon="mdi-account"
            :items="crewSignOnSupplier"
            :rules="supplierRules"
            :disabled="isDisabled"
            item-text="name"
            item-value="id"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0" v-show="!arrivalCrewSignOnService.metadata.arrange_by_owner" v-if="$root.hasAccess('can_manage_operation_amount_details')">
          <v-select
            label="Tax"
            placeholder="Select Tax"
            outlined
            dense
            v-model="arrivalCrewSignOnService.metadata.tax"
            prepend-inner-icon="mdi-cash-fast"
            :items="taxes"
            item-text="name"
            item-value="id"
          ></v-select>
        </v-col>
        <v-col cols="12" class="pb-0">
          <v-textarea
            label="Remarks"
            placeholder="Enter Remarks"
            outlined
            dense
            hide-details
            :rows="2"
            v-model="arrivalCrewSignOnService.remarks"
          ></v-textarea>
        </v-col>
        <v-col cols="12" class="pb-0">
          <v-btn class="float-right" color="success" outlined :disabled="loading" :loading="loading"
            @click="saveDetails">Save</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
  import rules from '../../../../utilities/validator';
  import _find from 'lodash/find';

  export default {
    name: 's-services-opted-crew-change-service',
    props: {
      operation: {
        type: Object,
        default: {},
      },
      isDraft: {
        type: Boolean,
        default: false,
      },
      servicesOpted: {
        type: Array,
        default: []
      }
    },
    data() {
      return {
        loading: false,
        form: false,
        isDisabled: false,
        rules,
        arrivalCrewSignOnService: {
          service_catalog_id: null,
          card_id: null,
          amount: 0,
          bill_type: 'Crew Sign On Service',
          remarks: '',
          metadata: {
            total_members_arrived: 0,
            price_per_member: 50,
            tax: '',
            arrange_by_owner: false
          }
        },
        crewSignOnSupplier: [],
        sinodaCrewSupplier: [],
        arrival_crew_calculated_amount: 0,
        taxes: ['0%', '9%']
      };
    },
    methods: {
      submit() {
        return this.$refs.form.validate();
      },
      handleOperationInit() {
        this.populateSuppliers();
      },
      initServiceOptedDetails() {
        const arrivalCrewSignOnService = _.find(this.servicesOpted, {bill_type: 'Crew Sign On Service'});
        if (arrivalCrewSignOnService) {
          this.arrivalCrewSignOnService = { ...arrivalCrewSignOnService };
          this.arrivalCrewSignOnService.amount = parseFloat(this.arrivalCrewSignOnService.amount || 0).toFixed(2);
          this.arrivalCrewSignOnService.metadata.price_per_member = parseFloat(this.arrivalCrewSignOnService.metadata.price_per_member || 0).toFixed(2);
          this.arrival_crew_calculated_amount = parseInt(this.arrivalCrewSignOnService.metadata.total_members_arrived || 0) * parseInt(this.arrivalCrewSignOnService.metadata.price_per_member || 0);
          if (arrivalCrewSignOnService.metadata && arrivalCrewSignOnService.metadata.arrange_by_owner !== undefined) {
            this.arrivalCrewSignOnService.metadata.arrange_by_owner = arrivalCrewSignOnService.metadata.arrange_by_owner;
          }
        }
      },
      calculateArrivalSignonPrice() {
        this.arrival_crew_calculated_amount = parseInt(this.arrivalCrewSignOnService.metadata.total_members_arrived) * parseInt(this.arrivalCrewSignOnService.metadata.price_per_member);
        this.arrivalCrewSignOnService.amount = this.arrival_crew_calculated_amount;
      },
      populateSuppliers() {
        this.$api.service_catalogs.get_suppliers_list('Crew Sign On Service')
          .then((response) => {
            this.crewSignOnSupplier = response.suppliers;
            this.sinodaCrewSupplier = _find(this.crewSignOnSupplier, { name: 'Sinoda' });
            if (this.sinodaCrewSupplier) {
              this.arrivalCrewSignOnService.card_id = this.sinodaCrewSupplier.id;
              this.isDisabled = true;
            }
            this.arrivalCrewSignOnService.service_catalog_id = response.service_catalog_id;
            this.initServiceOptedDetails();
            // Reset card_id to Sinoda Supplier if it's undefined
            if (this.sinodaCrewSupplier && !this.arrivalCrewSignOnService.card_id) {
              this.arrivalCrewSignOnService.card_id = this.sinodaCrewSupplier.id;
            }
          }).catch((err) => {
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          });
      },
      saveDetails() {
        if(!this.submit()) return;
        this.loading = true;
        this.arrivalCrewSignOnService = Object.assign(this.arrivalCrewSignOnService, {
          operation_id: this.operation.id
        });

        Promise.all([
            this.$api.operation_billed_items.save_service(this.arrivalCrewSignOnService)
          ]).then(() => {
            this.$store.dispatch('setAlert', {
              status: 'success',
              show: true,
              message: 'Crew Change service updated successfully',
            });
          }).catch((err) => {
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          }).finally(() => (this.loading = false));
      }
    },
    computed: {
      amountRules() {
        if (this.arrivalCrewSignOnService.metadata.arrange_by_owner) {
          return [this.rules.negative];
        } else {
          if (this.isDraft || (this.operation && this.operation.is_draft)) {
            return [this.rules.required, this.rules.negative];
          } else {
            return [this.rules.negative];
          }
        }
      },
      arrivalCrewHintText() {
        if (Number(this.arrivalCrewSignOnService.amount) !== this.arrival_crew_calculated_amount) {
          return `Arrival crew sign on service charges differs from calculated amount \S$${this.arrival_crew_calculated_amount}`;
        } else {
          return "";
        }
      },
      supplierRules() {
        if (this.arrivalCrewSignOnService.metadata.arrange_by_owner) {
          return [];
        } else {
          return [this.rules.required];
        }
      }
    },
    watch: {
      operation(value) {
        this.handleOperationInit();
      },
    },
    created() {
      this.handleOperationInit();
    },
  };
</script>